import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import SignContext from "../contextAPI/Context/SignContext";

const AssignDashboard = () => {
    const { GetAssignTaskBySpecDepartmentTypeandTaskType, GetAssignTaskByDepartmentTypeandEmployeeName } = useContext(SignContext);
    document.title = "SubDashboard";
    const [tasks, setTasks] = useState([]);
    const [tasks1, setTasks1] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [documents1, setDocuments1] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id1, id2 } = useParams();
    console.log("ID", id1, id2);

    const gettingid = async () => {
        const res = await GetAssignTaskBySpecDepartmentTypeandTaskType(id1, id2);
        console.log("Is Megha Really Dumb??", res.success);
        setTasks(res.data);

        const documentsArray = [];

        if (res.data && res.data.length > 0) {
            for (const item of res.data) {
                if (item.formlink) {
                    documentsArray.push(item.formlink);
                } else if (item.uploaddocument) {
                    documentsArray.push(`${process.env.REACT_APP_BASE_URL}/${item.uploaddocument}`);
                } else if (item.documentlink) {
                    documentsArray.push(item.documentlink);
                } else {
                    documentsArray.push(null);
                }
            }
        }

        setDocuments(documentsArray);
    };

    const gettingid1 = async () => {
        const res = await GetAssignTaskByDepartmentTypeandEmployeeName(id1, id2);
        console.log("Is Megha Really Dumb??", res.success);
        setTasks1(res.data);

        const documentsArray1 = [];

        if (res.data && res.data.length > 0) {
            for (const item of res.data) {
                if (item.formlink) {
                    documentsArray1.push(item.formlink);
                } else if (item.uploaddocument) {
                    documentsArray1.push(`${process.env.REACT_APP_BASE_URL}/${item.uploaddocument}`);
                } else if (item.documentlink) {
                    documentsArray1.push(item.documentlink);
                } else {
                    documentsArray1.push(null);
                }
            }
        }

        setDocuments1(documentsArray1);
    };

    useEffect(() => {
        const fetchData = async () => {
            await gettingid();
            await gettingid1();
            setLoading(false);
        };
        fetchData();
    }, [id1, id2]);

    console.log("tasks", tasks);
    console.log("documents", documents);

    return (
        <div className="bg-white page-content">
            <Container fluid>
                <Row>
                    <div className="d-flex justify-content-end">
                        <Link to={`/Dashboard`}>
                            <button
                                className="custom_hover btn btn-primary btn-color"
                                type="submit"
                                style={{
                                    display: "flex",
                                    fontSize: "18px",
                                    width: "100%",
                                    marginBottom: "10px",
                                }}
                            >
                                <i className="ri-function-line me-1 fs-18"></i>Dashboard
                            </button>
                        </Link>
                    </div>
                    <div className="d-flex flex-wrap">
                        {loading ? (
                            <div>Loading...</div>
                        ) : tasks && tasks.length > 0 ? (
                            tasks.map((task, index) => (
                                <Col md={4} key={task._id} className="px-2">
                                    <a href={documents[index]} target="_blank" rel="noopener noreferrer" className="card-link">
                                        <Card className="card-animate card-res" style={{ borderRadius: "15px" }}>
                                            <CardBody className="rounded-20">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <p className="fw-semibold new-class fs-14 mb-0">
                                                            {task.tasktypes.taskName}
                                                        </p>
                                                        <ul className="ps-0 fs-14 mt-3" style={{ fontWeight: "800", listStyleType: "none", color: "#495057" }}>
                                                            <li>{task.documentdescription}</li>
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <div className="avatar-sm flex-shrink-0">
                                                            <span className="avatar-title rounded-circle fs-2" style={{ backgroundColor: 'green' }}>
                                                                <FeatherIcon icon="file-text" className="text-white" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </a>
                                </Col>
                            ))
                        ) : tasks1 && tasks1.length > 0 ? (
                            tasks1.map((task, index) => (
                                <Col md={4} key={task._id} className="px-2">
                                    <a href={documents1[index]} target="_blank" rel="noopener noreferrer" className="card-link">
                                        <Card className="card-animate card-res" style={{ borderRadius: "15px" }}>
                                            <CardBody className="rounded-20">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <p className="fw-semibold new-class fs-16 mb-0">
                                                            {task.tasktypes.taskName}
                                                        </p>
                                                        <ul className="ps-0 fs-14 mt-3" style={{ fontWeight: "800", listStyleType: "none", color: "#495057" }}>
                                                            <li>{task.documentdescription}</li>
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <div className="avatar-sm flex-shrink-0">
                                                            <span className="avatar-title rounded-circle fs-2" style={{ backgroundColor: 'red' }}>
                                                                <FeatherIcon icon="file-text" className="text-white" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </a>
                                </Col>
                            ))
                        ) : (
                            <div>No tools assigned</div>
                        )}
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default AssignDashboard;
