import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { toast, ToastContainer } from "react-toastify";
import SignContext from "../../../../contextAPI/Context/SignContext";
import progileBg from "../../../../assets/images/profile-bg.jpg";
import avatar1 from "../../../../assets/images/users/user-dummy-img.jpg";

const Settings = () => {
  const url = `${process.env.REACT_APP_BASE_URL}`;
  const { id } = useParams();
  const { updateUser, changeUserPassword, getSpecificUser } =
    useContext(SignContext);
  const [AdminInfo, setAdminInfo] = useState({
    name: "",
    oldPassword: "",
    newPassword: "",
    profileImage: avatar1, // Default image to prevent undefined error
  });
  const [Error, setError] = useState("");
  const [Success, setSuccess] = useState("");
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("1");
  const [selectedFile, setSelectedFile] = useState(null); // State to hold selected file
  const [userprofileImage, setprofileImage] = useState(null); // State to hold preview image
  const [NewprofileImage, setNewprofileImage] = useState(null); // State to hold preview image

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleChange = (e) => {
    setAdminInfo({ ...AdminInfo, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file); // Set the selected file
      setAdminInfo({ ...AdminInfo, profileImage: URL.createObjectURL(file) }); // Set AdminInfo.profileImage to the URL of the selected file
      setNewprofileImage(URL.createObjectURL(file)); // Set the preview image
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedFile) {
      const formData = new FormData();
      formData.append("profileImage", selectedFile); // Append the file to FormData

      try {
        // Make API call to upload the file
        const response = await fetch(`${url}/user/updateuser/${id}`, {
          method: "PUT",
          body: formData,
        });

        if (!response.ok) {
          throw new Error("Failed to upload image");
        }

        // Parse the response JSON
        const result = await response.json();

        if (result.success) {
          // If upload successful, update user info with image URL
          const updatedInfo = { ...AdminInfo, profileImage: result.image };
          const res = await updateUser(updatedInfo, id);

          if (res.success) {
            setSuccess(res.msg);
            toast.success("Profile Updated Successfully", { autoClose: 2000 });
          } else {
            setError(res.msg);
            setTimeout(() => setError(""), 2000);
          }
        } else {
          throw new Error(result.msg);
        }
      } catch (error) {
        setError(error.message);
        setTimeout(() => setError(""), 2000);
      }
    } else {
      // If no file selected, update only user info without image
      const res = await updateUser(AdminInfo, id);
      if (res.success) {
        setSuccess(res.msg);
        toast.success("Profile Updated Successfully", { autoClose: 2000 });
      } else {
        setError(res.msg);
        setTimeout(() => setError(""), 2000);
      }
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    const token = window.localStorage.getItem("authToken");
    const res = await changeUserPassword(AdminInfo, token);
    if (res.success) {
      setSuccess(res.msg);
      navigate("/login");
    } else {
      setError(res.msg);
      setTimeout(() => setError(""), 2000);
    }
  };

  const fetchSpecificUser = async (id) => {
    try {
      const res = await getSpecificUser(id);
      if (res.success) {
        setAdminInfo(res); // Update AdminInfo with fetched data
        if (res.profileImage) {
          setprofileImage(res.profileImage); // Set the preview image from fetched data if available
        }
      } else {
        setError(res.msg);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchSpecificUser(id);
  }, [id]); // Ensure to fetch user when id changes

  document.title = "Profile Settings | OPA";

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <div className="page-content">
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              <img
                src={progileBg}
                className="profile-wid-img"
                alt="profile background"
              />
            </div>
          </div>
          <Row>
            <Col xxl={3}>
              <Card className="mt-n5">
                <CardBody className="p-4">
                  <Form onSubmit={(e) => handleSubmit(e)}>
                    <div className="text-center">
                      <div className="profile-user position-relative d-inline-block mx-auto mb-4">
                        <input
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          onChange={handleFileChange}
                          className="form-control"
                          style={{ display: "none" }}
                          id="profile-img-file-input"
                        />
                        <label
                          htmlFor="profile-img-file-input"
                          className="profile-photo-edit avatar-xs"
                        >
                          <span className="avatar-title rounded-circle bg-light text-body">
                            <i className="ri-camera-fill"></i>
                          </span>
                        </label>
                        <img
                          src={
                            NewprofileImage ||
                            `${process.env.REACT_APP_BASE_URL}/${userprofileImage}` ||
                            avatar1
                          }
                          className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                          alt="user-profile"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = avatar1;
                          }}
                        />
                      </div>
                      <h5 className="fs-16 mb-1">{AdminInfo?.name}</h5>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>

            <Col xxl={9}>
              <Card className="mt-xxl-n5">
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => tabChange("1")}
                      >
                        <i className="fas fa-home"></i> Personal Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => tabChange("2")}
                      >
                        <i className="far fa-user"></i> Change Password
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Form onSubmit={(e) => handleSubmit(e)}>
                        <Row>
                          <Col lg={12}>
                            <div className="mb-3">
                              <Label
                                htmlFor="firstnameInput"
                                className="form-label"
                              >
                                Name
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="firstnameInput"
                                placeholder="Enter your name"
                                name="name"
                                value={AdminInfo.name}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <button type="submit" className="btn btn-primary">
                                Update
                              </button>
                              <button
                                type="button"
                                className="btn btn-soft-success"
                                onClick={() => navigate("/pages-profile")}
                              >
                                Cancel
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>

                    <TabPane tabId="2">
                      {Error && (
                        <Alert
                          style={{ backgroundColor: "red", color: "white" }}
                        >
                          {Error}
                        </Alert>
                      )}
                      {Success && <Alert severity="success">{Success}</Alert>}
                      <Form onSubmit={(e) => handlePasswordSubmit(e)}>
                        <Row className="g-2">
                          <Col lg={4}>
                            <div>
                              <Label
                                htmlFor="oldpasswordInput"
                                className="form-label"
                              >
                                Old Password*
                              </Label>
                              <Input
                                type="password"
                                className="form-control"
                                id="oldpasswordInput"
                                placeholder="Enter current password"
                                name="oldPassword"
                                value={AdminInfo.oldPassword}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div>
                              <Label
                                htmlFor="newpasswordInput"
                                className="form-label"
                              >
                                New Password*
                              </Label>
                              <Input
                                type="password"
                                className="form-control"
                                id="newpasswordInput"
                                placeholder="Enter new password"
                                name="newPassword"
                                value={AdminInfo.newPassword}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div>
                              <Label
                                htmlFor="confirmpasswordInput"
                                className="form-label"
                              >
                                Confirm Password*
                              </Label>
                              <Input
                                type="password"
                                className="form-control"
                                id="confirmpasswordInput"
                                placeholder="Confirm new password"
                              />
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <button type="submit" className="btn btn-primary">
                                Change Password
                              </button>
                              <button
                                type="button"
                                className="btn btn-soft-success"
                                onClick={() => navigate("/pages-profile")}
                              >
                                Cancel
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Settings;
