//category
export const GET_CATEGORY = "GET_CATEGORY";

// sub category
export const GET_SUB_CATEGORY = "GET_SUB_CATEGORY";

// sub sub category
export const GET_SUB_SUB_CATEGORY = "GET_SUB_SUB_CATEGORY";

// gst
export const GET_GST = "GET_GST"

//
export const GET_PRODUCTS = "GET_PRODUCTS"


export const GET_COLOR = "GET_COLOR"
export const GET_SEASON = "GET_SEASON"
export const GET_MATERIAL = "GET_MATERIAL"
export const GET_DAILY_PRICE = "GET_DAILY_PRICE"



