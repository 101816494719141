import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import { useParams, Link } from "react-router-dom"; // Added Link
import SignContext from "../contextAPI/Context/SignContext";

const SubDashboard = () => {
  const { GetSpecificAddTaskByDeptId } = useContext(SignContext);
  document.title = "SubDashboard";
  const [tasks, setTasks] = useState([]);
  const { id } = useParams();

  const gettingid = async () => {
    console.log("hello");
    const res = await GetSpecificAddTaskByDeptId(id);
    console.log(">>>>", res.data.length);
    setTasks(res.data);
  };

  useEffect(() => {
    gettingid();
  }, [id]);

  return (
    <div className="bg-white page-content">
      <Container fluid>
        <Row>
          <div className="d-flex justify-content-end">
            <Link to={`/Dashboard`}>
              <button
                className="custom_hover btn btn-primary btn-color"
                type="submit"
                style={{
                  display: "flex",
                  fontSize: "18px",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <i className="ri-function-line me-1 fs-18"></i>Dashboard
              </button>
            </Link>
          </div>
          <div className="d-flex flex-wrap ">
            {tasks && tasks.length > 0 ? (
              tasks.map((task, index) => (
                <Col md={4} key={index} className="px-2">
                  {/* Wrap the Card inside the Link */}
                  <Link to={`/assigndashboard/${task.departmentType._id}/${task._id}`}>
                    <div class="col-lg-12 col-lg-4">
                      <Card
                        className="card-animate card-res"
                        style={{ borderRadius: "15px" }}
                      >
                        <CardBody>
                          <div className="d-flex justify-content-between">
                            <div>
                              <h5 className="fw-semibold new-class fs-16 mb-0">
                                {task.taskType}
                              </h5>
                              <h2 className="mt-4 ff-secondary fw-semibold">
                                {task.taskName}
                              </h2>
                              {/* <h2 className="mt-4 ff-secondary fw-semibold">
                                Assigned By : {task.assignedby}
                              </h2> */}
                              <p>{task.detail}</p>
                            </div>
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-soft-info rounded-circle fs-2">
                                <FeatherIcon
                                  icon="file-text"
                                  className="text-info"
                                />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </Link>
                </Col>
              ))
            ) : (
              <div>No tasks available</div>
            )}
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default SubDashboard;
