import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

import { gapi } from 'gapi-script';
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  Spinner,
  FormFeedback,
  Alert,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import logo from "../../assets/images/login_logo.png";
import { GoogleLogin } from "react-google-login";
//redux
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import SignContext from "../../contextAPI/Context/SignContext";
// const process.env.REACT_APP_BASE_URL = `${process.env.REACT_APP_BASE_URL}`;
let arr = []

let Roles = "";
let cms = ""
let idArray = []
let menumaster = ""
let addtask = ""
let dashboard = ""
let communityupdatemaster = ""
let employeemaster = ""
let roless = ""
let assignmaster = ""
let locationmaster = ""
let employeerole = ""
let departmentgroup = ""

const Login = () => {
  const [others, setothers] = useState(null);
  const { loginUser } = useContext(SignContext);
  const navigate = useNavigate();
  const [AdminInfo, setAdminInfo] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setAdminInfo({ ...AdminInfo, [e.target.name]: e.target.value });
  };


  // This effect will run whenever selectedRole changes


  const [Error, setError] = useState("");
  const [Success, setSuccess] = useState("");
  const [confirmPasswordShow, setconfirmPasswordShow] = useState(false);
  const [buttnLoading, setButtnLoading] = useState(false);

  const handleGoogleSuccess = async (response) => {
    // console.log("Nenenen", response);

    try {
      const serverResponse = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/google-login-authentication`, {
        email: response.profileObj.email,
      });
      // console.log("Hii", serverResponse.success);
      // console.log(serverResponse);

      if (serverResponse.success === true) {

        const currentTimestamp = new Date();

        // Get day, month, year, hour, minute, and AM/PM components
        const day = currentTimestamp.getDate();
        const month = currentTimestamp.toLocaleString('en-US', { month: 'long' });
        const year = currentTimestamp.getFullYear();
        const hours = currentTimestamp.getHours();
        const minutes = currentTimestamp.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour format
        const formattedHours = hours % 12 || 12;

        // Create the formatted timestamp string
        const formattedTimestamp = `${day}-${month.toLowerCase()}-${year}-${formattedHours}:${minutes} ${ampm}`;
        localStorage.removeItem("loggedIn", true);
        localStorage.removeItem("authToken", serverResponse.token);

        localStorage.removeItem("ID", serverResponse._id);
        localStorage.removeItem("LocationID", serverResponse.locationSchema);
        localStorage.removeItem("DepartmentGroupID", serverResponse.departmentGroup);
        localStorage.removeItem("DepartmentTypeID", serverResponse.departmentType);
        localStorage.removeItem("EmployeeRoleID", serverResponse.employeeRole);
        localStorage.removeItem("EmployeeNameID", serverResponse.employeeName);
        localStorage.removeItem("LocationSchema", serverResponse.LocationMaster);
        localStorage.removeItem("CommunityUpdateMaster", serverResponse.CommunityUpdateMaster);
        localStorage.removeItem("AdminUser", serverResponse.AdminUser);
        localStorage.removeItem("Roles", serverResponse.Roles);
        localStorage.removeItem("MenuMaster", serverResponse.MenuMaster);
        localStorage.removeItem("Dashboard", serverResponse.Dashboard);
        localStorage.removeItem("isActive", serverResponse.isActive);
        localStorage.removeItem("DepartmentGroup", serverResponse.DepartmentGroup);
        localStorage.removeItem("DepartmentType", serverResponse.DepartmentType);
        localStorage.removeItem("EmployeeRole", serverResponse.EmployeeRole);
        localStorage.removeItem("Employeemaster", serverResponse.Employeemaster);
        localStorage.removeItem("AddTask", serverResponse.AddTask);
        localStorage.removeItem("AssignMaster", serverResponse.AssignMaster);
        localStorage.removeItem("CMS", serverResponse.CMS);
        // Set the formatted timestamp in local storage
        localStorage.setItem("timestamp", formattedTimestamp);
        localStorage.setItem("loggedIn", true);
        localStorage.setItem("authToken", serverResponse.token);
        localStorage.setItem("ID", serverResponse._id);
        localStorage.setItem("LocationID", serverResponse.locationSchema);
        localStorage.setItem("DepartmentGroupID", serverResponse.departmentGroup);
        localStorage.setItem("DepartmentTypeID", serverResponse.departmentType);
        localStorage.setItem("EmployeeRoleID", serverResponse.employeeRole);
        localStorage.setItem("EmployeeNameID", serverResponse.employeeName);
        localStorage.setItem("LocationSchema", serverResponse.LocationMaster);
        localStorage.setItem("CommunityUpdateMaster", serverResponse.CommunityUpdateMaster);
        localStorage.setItem("AdminUser", serverResponse.AdminUser);
        localStorage.setItem("Roles", serverResponse.Roles);
        localStorage.setItem("MenuMaster", serverResponse.MenuMaster);
        localStorage.setItem("Dashboard", serverResponse.Dashboard);
        localStorage.setItem("isActive", serverResponse.isActive);
        localStorage.setItem("DepartmentGroup", serverResponse.DepartmentGroup);
        localStorage.setItem("DepartmentType", serverResponse.DepartmentType);
        localStorage.setItem("EmployeeRole", serverResponse.EmployeeRole);
        localStorage.setItem("Employeemaster", serverResponse.Employeemaster);
        localStorage.setItem("AddTask", serverResponse.AddTask);
        localStorage.setItem("AssignMaster", serverResponse.AssignMaster);
        localStorage.setItem("CMS", serverResponse.CMS);
        localStorage.setItem("name", serverResponse.name);
        navigate("/dashboard");
        window.location.reload();


      } else {
        // Handle the case where the server response indicates failure
        // console.error('Error during Google login:', serverResponse.data.message);
        alert("Please Enter a Valid Email Id")
      }
    } catch (error) {
      // console.error('Error during Google login:', error);
      alert("Please Enter a Valid Email Id");
    }

  };

  const handleGoogleFailure = (error) => {
    // console.error('Google login failure:', error);

  };




  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtnLoading(true);
    //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

    // // Check if email is provided and matches the regex pattern
    // if (!AdminInfo.email || !emailRegex.test(AdminInfo.email)) {
    //   setError("Please provide a valid email address");
    //   setButtnLoading(false);
    //   return;
    // }

    try {
      //  const response = await axios.get('')
      // If the selected role is User, hit the /user/authentication endpoint
      let apiEndpoint = `${process.env.REACT_APP_BASE_URL}/user/authentication`;

      const res = await axios.post(apiEndpoint, AdminInfo);
      console.log();
      if (res.success && res.isActive === true) {


        localStorage.removeItem("loggedIn", true);
        localStorage.removeItem("authToken", res.token);

        localStorage.removeItem("ID", res._id);
        localStorage.removeItem("LocationID", res.locationSchema);
        localStorage.removeItem("DepartmentGroupID", res.departmentGroup);
        localStorage.removeItem("DepartmentTypeID", res.departmentType);
        localStorage.removeItem("EmployeeRoleID", res.employeeRole);
        localStorage.removeItem("EmployeeNameID", res.employeeName);
        localStorage.removeItem("LocationSchema", res.LocationMaster);
        localStorage.removeItem("CommunityUpdateMaster", res.CommunityUpdateMaster);
        localStorage.removeItem("AdminUser", res.AdminUser);
        localStorage.removeItem("Roles", res.Roles);
        localStorage.removeItem("MenuMaster", res.MenuMaster);
        localStorage.removeItem("Dashboard", res.Dashboard);
        localStorage.removeItem("isActive", res.isActive);
        localStorage.removeItem("DepartmentGroup", res.DepartmentGroup);
        localStorage.removeItem("DepartmentType", res.DepartmentType);
        localStorage.removeItem("EmployeeRole", res.EmployeeRole);
        localStorage.removeItem("Employeemaster", res.Employeemaster);
        localStorage.removeItem("AddTask", res.AddTask);
        localStorage.removeItem("AssignMaster", res.AssignMaster);
        localStorage.removeItem("CMS", res.CMS);

        localStorage.setItem("loggedIn", true);
        localStorage.setItem("authToken", res.token);
        const currentTimestamp = new Date();

        // Get day, month, year, hour, minute, and AM/PM components
        const day = currentTimestamp.getDate();
        const month = currentTimestamp.toLocaleString('en-US', { month: 'long' });
        const year = currentTimestamp.getFullYear();
        const hours = currentTimestamp.getHours();
        const minutes = currentTimestamp.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour format
        const formattedHours = hours % 12 || 12;

        // Create the formatted timestamp string
        const formattedTimestamp = `${day}-${month.toLowerCase()}-${year}-${formattedHours}:${minutes} ${ampm}`;

        // Set the formatted timestamp in local storage
        localStorage.setItem("timestamp", formattedTimestamp);
        localStorage.setItem("Role", res.Roles)

        localStorage.setItem("ID", res._id);
        localStorage.setItem("LocationID", res.locationSchema);
        localStorage.setItem("DepartmentGroupID", res.departmentGroup);
        localStorage.setItem("DepartmentTypeID", res.departmentType);
        localStorage.setItem("EmployeeRoleID", res.employeeRole);
        localStorage.setItem("EmployeeNameID", res.employeeName);
        localStorage.setItem("LocationSchema", res.LocationMaster);
        localStorage.setItem("CommunityUpdateMaster", res.CommunityUpdateMaster);
        localStorage.setItem("AdminUser", res.AdminUser);
        localStorage.setItem("Roles", res.Roles);
        localStorage.setItem("MenuMaster", res.MenuMaster);
        localStorage.setItem("Dashboard", res.Dashboard);
        localStorage.setItem("isActive", res.isActive);
        localStorage.setItem("DepartmentGroup", res.DepartmentGroup);
        localStorage.setItem("DepartmentType", res.DepartmentType);
        localStorage.setItem("EmployeeRole", res.EmployeeRole);
        localStorage.setItem("Employeemaster", res.Employeemaster);
        localStorage.setItem("AddTask", res.AddTask);
        localStorage.setItem("AssignMaster", res.AssignMaster);
        localStorage.setItem("CMS", res.CMS);


        // console.log("Hii",count)
        navigate("/dashboard")
        const count = window.location.reload();


      } else if (res.success && res.isActive === false) {
        setError("You are Not Allowed to Access")
      }
      else {
        setError("Please Enter Email & Password");
        // setTimeout(() => {
        //   setError("Please Enter the Correct Password");
        // }, 3000);

      }

      setButtnLoading(false);
    } catch (error) {
      const code = error.split(" ")[error.split(" ").length - 1];
      // console.log("colled",code, error);
      if (code === "401") {
        setError("Invalid credentials. Please check your email and password.");
      } else if (code === "407") {
        setError(
          "Please Enter a Correct Password"
        );
      } else {
        setError("Please Enter Valid Credentials");
      }
      setButtnLoading(false);

      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };
  useEffect(() => {
    // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",others)
  }, [others])
  document.title = "OPA | Login";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">

          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <img
                      src={logo}
                      alt="OPA"
                      style={{ maxHeight: "52px" }}
                    ></img>
                  </div>
                  {/* <p className="mt-3 fs-15 fw-medium">
                    Premium Admin & Dashboard Template
                  </p> */}
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-color text-primary abc1111 yammo-bold ">Welcome Back !</h5>
                      <p className="text-muted">
                        Sign in to continue to One Portal for All (OPA) System.
                      </p>
                    </div>
                    {Error && Error ? (
                      <Alert color="danger"> {Error} </Alert>
                    ) : null}
                    {Success && Success ? (
                      <Alert color="success"> {Success} </Alert>
                    ) : null}
                    <div className="p-2 mt-4">
                      <Form onSubmit={(e) => handleSubmit(e)}>
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            Email
                          </Label>
                          <Input
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            name="email"
                            value={AdminInfo.email}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                          {Error && !AdminInfo.email.match(/\.com$|\.net$/) ? (
                            <small className="text-danger">Invalid Email </small>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <div className="float-end">
                            <Link to="/forgot-password" className="text-muted">
                              Forgot password?
                            </Link>
                          </div>
                          <Label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              className="form-control pe-5"
                              placeholder="Enter Password"
                              name="password"
                              type={confirmPasswordShow ? "text" : "password"}
                              value={AdminInfo.password}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                              onClick={() =>
                                setconfirmPasswordShow(!confirmPasswordShow)
                              }
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                          </div>
                        </div>

                        {/* <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="auth-remember-check"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Remember me
                          </Label>
                        </div> */}

                        {!buttnLoading ? (
                          <div className="mt-4">
                            <Button
                              color="success"
                              className="btn btn-success w-100"
                              type="submit"
                            >
                              Sign In
                            </Button>
                          </div>
                        ) : (
                          <Button
                            color="success"
                            className="btn-load  w-100"
                            outline
                            disabled
                          >
                            <span className="d-flex align-items-center">
                              <Spinner size="sm" className="flex-shrink-0">
                                {" "}
                                Loading...{" "}
                              </Spinner>
                              <span className="flex-grow-1 ms-2">
                                Loading...
                              </span>
                            </span>
                          </Button>
                        )}

                        <div className="mt-4 text-center">
                          <div className="signin-other-title">
                            <h5 className="yammo-bold fs-13 mb-4 title abc1111" style={{ background: '#e8f0fe' }}>Sign In with</h5>
                          </div>
                          <div className="btn_icon">
                            <GoogleLogin
                              clientId="388394949304-bmlvpqdssje2dl6kt72g02tcp6da9u3v.apps.googleusercontent.com"

                              render={(renderProps) => (
                                <button class="btn  btn-icon waves-effect waves-light"
                                  onClick={renderProps.onClick}
                                  disabled={renderProps.disabled}
                                // style={{ color: "red" }}
                                >
                                  <i
                                    className="fs-16 text-white ri-google-fill"
                                  //  style={{ color: "brown", fontSize: "30px" }}
                                  />
                                </button>
                              )}
                              onSuccess={handleGoogleSuccess}
                              onFailure={handleGoogleFailure}
                              cookiePolicy={'single_host_origin'}
                              prompt="select_account"
                              googleToken
                            />
                          </div>
                        </div>
                        {/* <div className="mt-4 text-center">
                          <p className="mb-0">
                            Forgot Password ?{" "}
                            <Link
                              to="/forgot-password"
                              className="fw-semibold text-primary text-decoration-underline"
                            >
                              {" "}
                              Forgot{" "}
                            </Link>{" "}
                          </p>
                        </div> */}
                      </Form>
                    </div>
                  </CardBody>
                </Card>


              </Col>
            </Row>
            <footer class="footer">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="text-center">
                      <p class="mb-0 text-muted">
                        &copy;{new Date().getFullYear()} OPA. Design & Development By : <a href="https://www.barodaweb.com/" target="_blank">Barodaweb | The E-catalogue Designer</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </Container>
        </div>


      </ParticlesAuth>
    </React.Fragment>
  );
};
export { Roles };
export default withRouter(Login);
export { cms, idArray, menumaster, addtask, dashboard, communityupdatemaster, employeemaster, roless, assignmaster, locationmaster, employeerole, departmentgroup }