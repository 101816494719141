import React, { useState, useEffect, useContext } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import axios from "axios";
import { FaRupeeSign } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { FaHourglassHalf } from "react-icons/fa";
import { FaUndo } from "react-icons/fa";
import { FaBan } from "react-icons/fa";
import { FaShoppingBag } from "react-icons/fa";
import FeatherIcon from "feather-icons-react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import pinImage from '../assets/images/pin.png';
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import "../Dashboard/dashboard.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import SignContext from "../contextAPI/Context/SignContext";

import {
  Pagination,
  Navigation,
  Scrollbar,
  EffectFade,
  EffectCreative,
  Mousewheel,
  EffectFlip,
  EffectCoverflow,
  Autoplay,
} from "swiper";

const NewDashboard = () => {
  document.title = "Dashboard";
  //const getReqCommDetails = useContext(SignContext)
  const id = localStorage.getItem("LocationID");
  const navigate = useNavigate();
  //const { id } = useParams();
  const {
    GetallDepartmentType,
    GetallDepartmentTypefordashboard,
    GetSpecificAddTaskByDeptId,
    deletetype,
  } = useContext(SignContext);
  const [communityrequireddetails, setcommunityrequireddetails] = useState(null);
  const [communityreginaldetails, setcommunityreginaldetails] = useState(null);
  const [rolesresponsibilities, setrolesresponsibility] = useState(null);
  const [admin, setadmin] = useState(null);
  const [addtask, setaddtask] = useState(null);
  const [assigntask, setassigntask] = useState(null);
  const [assignedtask, setassignedtask] = useState(null);
  const [commsg, setcommmsg] = useState(null);
  const [deptype, setdeptype] = useState([]);
  const [tasklength, settasklength] = useState(null);
  // const [response, setresponse] = useState(null);
  const [response, setresponse] = useState(null);
  const [documents, setDocuments] = useState([]);
  const empid = localStorage.getItem("EmployeeNameID")
  const locationid = localStorage.getItem("LocationID")
  const getreqcommdetails = async () => {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/communitymaster/getalllocationcommunitydetails`);
    console.log("Response:", res.data);
    setcommunityrequireddetails(res.data);
  };
  const getreginalUpdates = async () => {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/communitymaster/getReginalcommunitydetails/${locationid}`);
    console.log("Response:", res.data);
    setcommunityreginaldetails(res.data);
  };
  const getreqassigntask = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/assigntask/getassigntask`
    );
    console.log("jfjfijefjekf", res);
    setassigntask(res.assigncount);
  };
  const getalltask = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/assigntask/getassigntaskbyemployeeid/${empid}`
    );
    setassignedtask(res.data)
    const documentsArray = []; // Temporary array to store document paths or links
    // Check if the response has data
    if (res.data && res.data.length > 0) {
      // Iterate through the data to find the relevant documents
      for (const item of res.data) {
        // Check for formlink
        if (item.formlink) {
          documentsArray.push(item.formlink);
        }
        // Check for uploaddocument
        else if (item.uploaddocument) {
          documentsArray.push(`${process.env.REACT_APP_BASE_URL}/${item.uploaddocument}`);
        }
        // Check for documentlink
        else if (item.documentlink) {
          documentsArray.push(item.documentlink);
        }
        else {
          documentsArray.push(null);
        }
      }
    }

    // Set the documents array to the state
    setDocuments(documentsArray);
    console.log("AssignedTasks", res.data);
  };
  const getreqadmincount = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/auth/getadmins`
    );
    console.log("jfjfijefjekf", res);
    setadmin(res.adminCount);
  };
  const getrolesresponsibility = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/rolesresponsibilities/getRolesResponsibilities`
    );
    setrolesresponsibility(res.rolesResponsibilitiesCount);

    console.log(res.rolesResponsibilitiesCount);
  };

  const getcomdetails = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/communitymaster/getrequiredcommunitymessage`
    );
    setcommmsg(res.count);
  };
  const gettaskdetails = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/addtask/getalltask`
    );
    setaddtask(res.formTasksCount);
  };

  const getalldepartmenttye = async () => {
    const res = await GetallDepartmentTypefordashboard();
    setdeptype(res.data);
    console.log(">>dtype", res);
  };

  const gettingid = async (id) => {
    const res = await GetSpecificAddTaskByDeptId(id);
    console.log("number", res);

    settasklength(res.data.length);
  };
  const userID = localStorage.getItem("EmployeeNameID");
  let cleanedUserID = userID.trim().replace(/^["']+|["']+$/g, "");

  // console.log("AdminID", typeof userID);
  const getPinnedItem = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/pin/getPinnedItemsbyid/${cleanedUserID}`
    );
    console.log("pinneditems", res.data[0]);
    setresponse(res.data[0]);
  };
  useEffect(() => {
    getPinnedItem();
  }, [cleanedUserID]);
  const schema = {
    "Add Task": response?.AddTask,
    "Roles Responsibility": response?.RolesResponsibility,
    "Admin User": response?.AdminUser,
    "Assign Master": response?.AssignMaster,
    "Community Update Master": response?.CommunityUpdateMaster,
    Dashboard: response?.Dashboard,
    "Type of Function": response?.DepartmentGroup,
    "Department Type": response?.DepartmentType,
    "Employee Role": response?.EmployeeRole,
    "Employee Master": response?.Employeemaster,
    "Location Master": response?.LocationMaster,
    "Menu Master": response?.MenuMaster,
  };
  const trueKeys = Object.keys(schema).filter((key) => schema[key]);
  const handleRedirect = (field) => {
    // You can customize the URL or route based on your needs
    console.log(field);

    if (field === "Dashboard") {
      navigate("/dashboard");
    } if (field === "Menu Master") {
      navigate("/menumaster");
    }
    if (field === "Add Task") {
      navigate("/add-taskmaster");
    }
    if (field === "Assign Master") {
      navigate("/assign-master");
    }
    if (field === "Community Update Master") {
      navigate("/community-update");
    }
    if (field === "Type of Function") {
      navigate("/department-group");
    }
    if (field === "Department Type") {
      navigate("/department-type");
    }
    if (field === "Employee Role") {
      navigate("/employee-roles");
    }
    if (field === "Employee Master") {
      navigate("/employee-master");
    }
    if (field === "Location Master") {
      navigate("/location-master");
    }
    if (field === "Menu Master") {
      navigate("/menumaster");
    }
    if (field === "Roles Responsibility") {
      navigate("/roles-responsibilty");
    }
    if (field === "Admin User") {
      navigate("/admin-user");
    }

    // Define route mappings for each field
    const routeMappings = {
      AddTask: "/add-taskmaster",
      AssignMaster: "/assign-master",
      CommunityUpdateMaster: "/community-update-master",
      RolesResponsibility: "/roles-responsibilty",
      // Dashboard: '/dashboard',
      DepartmentGroup: "/department-group",
      DepartmentType: "/department-type",
      EmployeeRole: "/employee-role",
      Employeemaster: "/employee-master",
      LocationMaster: "/location-master",
      MenuMaster: "/menu-master",
      // Add more mappings as needed
    };

    // Check if the field is true and has a corresponding route
    if (field && routeMappings[field]) {
      // Redirect to the corresponding route
      // console.log(Redirecting to `${routeMappings[field]});
      // Example using React Router's useNavigate hook
      // navigate(routeMappings[field]);
    } else {
      // Handle default case or display a message
      console.log("Invalid field or no valid route found for redirection");
      // Handle default case or display a message to the user
    }
  };
  useEffect(() => {
    console.log(">>>", deptype);
  }, [deptype]);
  useEffect(() => {
    getrolesresponsibility();
    getreqadmincount();
    getreqassigntask();
    getreqcommdetails();
    getreginalUpdates();
    getcomdetails();
    gettaskdetails();
    getalldepartmenttye();
    getalltask();
  }, []);


  // useEffect(() => {
  //   gettingid();
  // }, [id]);
  console.log(rolesresponsibilities);
  console.log(commsg);
  console.log(admin);
  console.log(assigntask);
  const req_id = localStorage.getItem("DepartmentTypeID");
  useEffect(() => {
    // Set a delay for the execution
    const timer = setTimeout(() => {
      getreqcommdetails();
    }); // Delay the execution for 5000 milliseconds (5 seconds)
    const timer1 = setTimeout(() => {
      getreginalUpdates();
    });

    return () => clearTimeout(timer, timer1);
  }, []);
  console.log("documents", documents);

  const [searchTerm, setSearchTerm] = useState('');

  const searchList = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.replace("/");
  };

  // Set timeout for automatic logout after 1 hour
  useEffect(() => {
    let timeoutId;

    const startTimer = () => {
      timeoutId = setTimeout(() => {
        handleLogout();
        toast.warn("You have been logged out due to inactivity.");
      }, 600000); // 1 hour = 3600000 milliseconds
    };

    const resetTimer = () => {
      clearTimeout(timeoutId);
      startTimer();
    };

    // Start the timer when the component mounts
    startTimer();

    // Reset the timer whenever there is user interaction
    const handleUserInteraction = () => {
      resetTimer();
      // You can also perform other actions on user interaction if needed
    };

    document.addEventListener("mousemove", handleUserInteraction);
    document.addEventListener("keydown", handleUserInteraction);
    document.addEventListener("mousedown", handleUserInteraction);
    document.addEventListener("scroll", handleUserInteraction);

    // Cleanup function to remove event listeners
    return () => {
      document.removeEventListener("mousemove", handleUserInteraction);
      document.removeEventListener("keydown", handleUserInteraction);
      document.removeEventListener("mousedown", handleUserInteraction);
      document.removeEventListener("scroll", handleUserInteraction);
      clearTimeout(timeoutId);
    };
  }, []);

  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    // Fetch all department types
    GetallDepartmentType().then((data) => {
      setDepartments(data);
      console.log("Department Data", data);
    });
  }, []);

  return (
    <>
      <div className="bg-white page-content">
        <Container fluid>
          <Row>
            {/* <h5>
              <b>Dashboard</b>
            </h5> */}

            {/* <Col md={4}>
              <div class="col-lg-12 col-lg-4">
                <Card
                  className="card-animate card-res"
                  style={{ borderRadius: "15px" }}
                >
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <div>
                        <p class="fw-semibold new-class fs-16 mb-0">Users</p>

                        <h2 className="mt-4 ff-secondary fw-semibold">
                          <span className="counter-value">
                            {rolesresponsibilities}
                          </span>
                        </h2>
                      </div>
                      <div>
                        <div className="avatar-sm flex-shrink-0">
                          <span className="avatar-title bg-soft-info rounded-circle fs-2">
                            <FeatherIcon icon="users" className="text-info" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>

            <Col md={4}>
              <div class="col-lg-12 col-lg-4">
                <Card
                  className="card-animate card-custom card-res"
                  style={{ borderRadius: "15px" }}
                >
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <div>
                        <p class="fw-semibold new-class fs-16 mb-0">
                          No. of Document
                        </p>
                        <h2 className="mt-4 ff-secondary fw-semibold">
                          <span className="counter-value">
                            {commsg + admin + assigntask}
                          </span>
                        </h2>
                      </div>
                      <div>
                        <div className="avatar-sm flex-shrink-0">
                          <span className="avatar-title bg-soft-info rounded-circle fs-2">
                            <FeatherIcon icon="users" className="text-info" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col md={4}>
              <Card
                className="card-animate card-res"
                style={{ borderRadius: "15px" }}
              >
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <div>
                      <p class="fw-semibold new-class fs-16 mb-0">
                        No. of Form
                      </p>
                      <h2 className="mt-4 ff-secondary fw-semibold">
                        <span className="counter-value">{addtask}</span>
                      </h2>
                    </div>
                    <div>
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title bg-soft-info rounded-circle fs-2">
                          <FeatherIcon icon="file-text" className="text-info" />
                        </span>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
          </Row>

          {/* <Row>
            <Col lg={4}>
              <div style={{ display: "flex" }}>
                <label>
                  <img
                    src={pinImage}
                    style={{
                      width: "40px",
                      marginRight: "10px",
                    }}
                    alt="Pin"
                  />
                </label>
                <div style={{ fontSize: "22px", marginTop: "10px" }}>
                  Pinned Items
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            {trueKeys.map((field) => (
              <Col key={field} sm={3} onClick={() => handleRedirect(field)}>
                <Card
                  className="card-animate card-custom card-res"
                  style={{ borderRadius: "15px" }}
                >
                  <CardBody className="rounded-20">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p
                          className="fw-semibold new-class fs-16 mb-0"
                          style={{ textAlign: "center" }}
                        >
                          {field}
                        </p>
                      </div>

                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row> */}
          {/* vaishal */}

          {console.log("Nice", deptype)}
          {console.log("Nice", req_id)}
          {/* <div style={{ fontSize: "22px", marginTop: "10px" }}>
            Department
          </div> */}
          {/* <Row>
            {deptype &&
              deptype.length > 0 &&

              deptype.map((type, index) => {
                {
                  
                }
                if (type.departmentType._id === req_id) {
                  return (
                    <Col md={4} key={index}>
                      <Link to={`/subdashboard/${type.departmentType._id}`}>
                        <Card
                          className="card-animate card-res"
                          style={{ borderRadius: "15px" }}
                        >
                          <CardBody className="rounded-20">
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="fw-semibold new-class fs-16 mb-0 ">
                                  {type.departmentType.name}
                                </p>

                                <ul className="ps-0 fs-14 mt-3" style={{ fontWeight: '800', listStyleType: 'none', color: "#495057" }}>
                                  {type.taskNames.map((task, taskIndex) => (
                                    <li key={taskIndex}>{task}</li>
                                  ))}
                                </ul>
                                <h2
                                  className="mt-1 ff-secondary fs-14"
                                  style={{ fontWeight: "bold" }}
                                >
                                </h2>
                                <h2
                                  className="mt-1 ff-secondary fs-14"
                                  style={{ fontWeight: "bold" }}
                                >
                                </h2>
                              </div>
                              <div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title   rounded-circle fs-2">
                                    <FeatherIcon
                                      icon="file-text"
                                      className="text-white text-info"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                  );
                }

              })}
          </Row> */}
          {/* Task Cards*/}
          <Row style={{ padding: '20px 0px' }}>
            <Col>
              <div style={{ fontSize: "22px", marginTop: "10px" }}>
                Dashboard
              </div>
            </Col>
            <Col lg={2}>
              <div className="">
                <div className="search-box">
                  <input
                    type="text"
                    id="searchTaskList"
                    className="form-control search"
                    placeholder="Search "
                    onChange={searchList}
                  />
                  <i className="left_13 ri-search-line"></i>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="gy-4">
            {departments.data &&
              departments.data.length > 0 &&
              assignedtask &&
              assignedtask.length > 0 &&

              departments.data
                .map(department => {
                  // Filter tasks for the current department based on the search term
                  const departmentTasks = assignedtask.filter(task =>
                    task.documentdepartmenttype._id === department._id &&
                    (!searchTerm || task.documentname.toLowerCase().includes(searchTerm.toLowerCase()) || task.tasktypes.taskName.toLowerCase().includes(searchTerm.toLowerCase()))
                  );

                  return { ...department, tasks: departmentTasks };
                })
                .filter(department => !searchTerm || department.tasks.length > 0) // Only include departments with matching tasks when search term is present
                .sort((a, b) => b.tasks.length - a.tasks.length) // Sort departments so those with tasks come first
                .map((department, index) => {
                  const hasTasks = department.tasks.length > 0;

                  return (
                    <Col md={4} key={index} style={{ maxHeight: '140px', marginBottom: '15px', minHeight: '110px' }}>
                      <Link to={`/assigndashboard/${department._id}/${empid}`}>
                        <Card className="card-animate card-res h-100" style={{ borderRadius: "15px" }}>
                          <CardBody className="pb-0 rounded-20 d-flex flex-column">
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="fw-semibold new-class fs-20 mb-0 text-center">{department.name}</p>
                                {/* <ul className="mb-0 ps-0 fs-14 mt-3 " style={{ height: '60px', fontWeight: "800", listStyleType: "none", color: "#495057" }}>
                                  {department.tasks.slice(0, 3).map((task, taskIndex) => (
                                    <li key={taskIndex}>{task.tasktypes.taskName}</li>
                                  ))}
                                </ul> */}
                              </div>
                              <div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title rounded-circle fs-2" style={{ backgroundColor: hasTasks ? 'green' : 'red' }}>
                                    <FeatherIcon icon="file-text" className="text-white" />
                                  </span>
                                </div>
                              </div>
                            </div>
                            {department.tasks.length > 3 ? (
                              <div className="d-flex justify-content-end"
                                style={{ visibility: 'visible' }}>
                                {/* <Link to={`/assigndashboard/${department._id}/${empid}`} className="fs-12 text-end">Read more</Link> */}
                              </div>
                            ) : (
                              <div className="d-flex justify-content-end"
                                style={{ visibility: 'hidden' }}>
                                {/* <Link to={`/assigndashboard/${department._id}/${empid}`} className="fs-12 text-end">Read more</Link> */}
                              </div>
                            )}
                          </CardBody>
                        </Card>
                      </Link>

                    </Col>
                  );
                })}
          </Row>

          {/*Community Updates*/}
          <Row className='mt-3'>
            <Col lg={12}>
              <Card>
                <CardBody className="rounded-20">
                  <h4 className="mb-4 fs-4 " style={{ fontWeight: '800' }}>Group Level Updates</h4>
                  <hr />
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    pagination={{
                      el: ".swiper-pagination",
                      clickable: true,
                    }}
                    breakpoints={{
                      640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                      },
                    }}
                    loop={
                      communityrequireddetails &&
                      communityrequireddetails.length > 4

                    }
                    autoplay={{ delay: 1000 }}
                    onSwiper={(swiper) => console.log(swiper)}
                    modules={[Pagination, Autoplay]}
                    className="mySwiper swiper responsive-swiper rounded gallery-light pb-5"

                  >
                    <div className="swiper-wrapper">
                      <div className="swiper-wrapper">

                        {communityrequireddetails &&
                          communityrequireddetails.length > 0 ? (
                          communityrequireddetails.map((detail, index) => (
                            <SwiperSlide key={index} className="bg-white mr-0">

                              <div className="mb-0 gallery-box card w-100">
                                <div className="gallery-container">
                                  <Link
                                    className="image-popup"
                                    title={detail.name}
                                  >

                                    <img
                                      className="gallery-img img-fluid mx-auto"
                                      src={`${process.env.REACT_APP_BASE_URL}/${detail.uploadimage}`} // Adjust accordingly if using base64 strings
                                      alt={detail.name}

                                      style={{
                                        height: "170px",
                                        width: "100%",
                                      }}
                                    />
                                    <div className="gallery-overlay">
                                      <h6 className="overlay-caption fs-6" style={{ fontWeight: 'bold' }}>
                                        {/* Assuming you want to display all locations for this detail */}
                                        {/* {detail.locationSchema
                                        .map((location) => location.name)
                                        .join(", ")} */}
                                        {detail.updatedAt.split('T')[0]}
                                        <br />


                                        {/* Static time, replace if dynamic */}
                                        10:30AM - 1:15PM{" "}
                                      </h6>
                                    </div>
                                  </Link>
                                </div>
                                <div className="box-content">
                                  <div className="d-flex align-items-center mt-1">
                                    <h5 className="m-1 " style={{ color: 'rgb(16 118 238) ' }}>{detail.name}</h5>
                                  </div>
                                  <p className="m-1 ">{detail.message}</p>
                                </div>
                              </div>


                            </SwiperSlide>
                          ))
                        ) : (
                          <p>No Group Level Updates to display</p>
                        )}
                      </div>
                    </div>
                    <div className="swiper-pagination swiper-pagination-dark"></div>
                  </Swiper>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/*Regional Updates*/}
          <Row className='mt-3'>
            <Col lg={12}>
              <Card>
                <CardBody className="rounded-20">
                  <h4 className="mb-4 fs-4 " style={{ fontWeight: '800' }}> Regional Updates</h4>
                  <hr />
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    pagination={{
                      el: ".swiper-pagination",
                      clickable: true,
                    }}
                    breakpoints={{
                      640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                      },
                    }}
                    loop={
                      communityreginaldetails &&
                      communityreginaldetails.length > 4

                    }
                    autoplay={{ delay: 1000 }}
                    onSwiper={(swiper) => console.log(swiper)}
                    modules={[Pagination, Autoplay]}
                    className="mySwiper swiper responsive-swiper rounded gallery-light pb-5"

                  >
                    <div className="swiper-wrapper">
                      <div className="swiper-wrapper">

                        {communityreginaldetails &&
                          communityreginaldetails.length > 0 ? (
                          communityreginaldetails.map((detail, index) => (
                            <SwiperSlide key={index} className="bg-white mr-0">

                              <div className="mb-0 gallery-box card w-100">
                                <div className="gallery-container">
                                  <Link
                                    className="image-popup"
                                    title={detail.name}
                                  >

                                    <img
                                      className="gallery-img img-fluid mx-auto"
                                      src={`${process.env.REACT_APP_BASE_URL}/${detail.uploadimage}`} // Adjust accordingly if using base64 strings
                                      alt={detail.name}

                                      style={{
                                        height: "170px",
                                        width: "100%",
                                      }}
                                    />
                                    <div className="gallery-overlay">
                                      <h6 className="overlay-caption fs-6" style={{ fontWeight: 'bold' }}>
                                        {/* Assuming you want to display all locations for this detail */}
                                        {/* {detail.locationSchema
                                        .map((location) => location.name)
                                        .join(", ")} */}
                                        {detail.updatedAt.split('T')[0]}
                                        <br />


                                        {/* Static time, replace if dynamic */}
                                        10:30AM - 1:15PM{" "}
                                      </h6>
                                    </div>
                                  </Link>
                                </div>
                                <div className="box-content">
                                  <div className="d-flex align-items-center mt-1">
                                    <h5 className="m-1 " style={{ color: 'rgb(16 118 238) ' }}>{detail.name}</h5>
                                  </div>
                                  <p className="m-1 ">{detail.message}</p>
                                </div>
                              </div>


                            </SwiperSlide>
                          ))
                        ) : (
                          <p>No Regional updates to display</p>
                        )}
                      </div>
                    </div>
                    <div className="swiper-pagination swiper-pagination-dark"></div>
                  </Swiper>
                </CardBody>
              </Card>
            </Col>
          </Row>


        </Container>
      </div>
    </>
  );
};

export default NewDashboard;